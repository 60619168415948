html,
body,
p,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1200px;
}
img{
  vertical-align: bottom;
}
ul,ol {
  list-style: none;
}
@width: 1200px;
